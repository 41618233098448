// src/services/contactService.js

const apiUrl = process.env.REACT_APP_ORPHIMUSE_BE_API_URL;
const apiEndpoint = `${apiUrl}/api/contact/`;  

// Function to handle sending a contact message
export const sendContactMessage = async (formData) => {
  try {
    const response = await fetch(apiEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      throw new Error('Failed to send message. Please try again.');
    }

    return response.json();
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};
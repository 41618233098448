import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { pricingPageContent } from '../../content/pricingPageContent'; // Import pricing content

// Keyframe Animations for Smooth Effects
const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const bounce = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-5px);
  }
`;

// Styled Components
const PricingPage = styled.div`
  text-align: center;
  padding: 80px 20px;
  font-family: 'Open Sans', sans-serif;
  background-color: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.text};
  animation: ${fadeInUp} 0.8s ease-out;
`;

const Title = styled.h1`
  font-family: 'Montserrat', sans-serif;
  font-size: 48px;
  color: ${(props) => props.theme.colors.primary};
  margin-bottom: 10px;
  position: relative;
  animation: ${fadeInUp} 0.5s ease-out;
  &::after {
    content: '';
    display: block;
    width: 80px;
    height: 4px;
    background: ${(props) => props.theme.colors.primary};
    margin: 10px auto;
    border-radius: 2px;
  }
`;

const Subtitle = styled.p`
  font-size: 18px;
  color: ${(props) => props.theme.colors.secondaryText};
  margin-bottom: 40px;
  letter-spacing: 0.5px;
  animation: ${fadeInUp} 0.6s ease-out;
`;

const BillingToggle = styled.div`
  display: inline-flex;
  margin: 20px 0;
  background-color: #f0f4f8;
  border-radius: 50px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: ${fadeInUp} 0.7s ease-out;
  border: 1px solid ${(props) => props.theme.colors.primaryLight};
`;

const BillingButton = styled.button`
  flex: 1;
  padding: 12px 30px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  background: ${(props) => (props.$isActive ? props.theme.colors.primary : '#f0f4f8')};
  color: ${(props) => (props.$isActive ? '#fff' : props.theme.colors.primary)};
  transition: background-color 0.3s, color 0.3s, transform 0.2s;
  border-radius: 50px;
  font-weight: ${(props) => (props.$isActive ? 'bold' : 'normal')};

  &:hover {
    background-color: ${(props) => props.theme.colors.primaryDark};
    color: #fff;
    transform: translateY(-2px);
  }
`;

const PricingPlans = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-top: 50px;
  flex-wrap: wrap;
  animation: ${fadeInUp} 0.8s ease-out;
`;

const Plan = styled.div`
  position: relative;
  border: 2px solid #ddd;
  border-radius: 15px;
  padding: 35px;
  width: 300px;
  background-color: #fff;
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  text-align: center;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  }

  &.highlight {
    border: 2px solid #ff9800;
    transform: scale(1.05);
    box-shadow: 0 15px 30px rgba(255, 152, 0, 0.4);
    z-index: 1;
  }
`;

const PlanTitle = styled.h2`
  font-size: 26px;
  color: ${(props) => props.theme.colors.primary};
  font-weight: bold;
  margin-bottom: 20px;
`;

const PlanPrice = styled.h3`
  margin: 25px 0;
  font-size: 30px;
  color: #11998e; /* Turquoise color for price */
  font-weight: bold;
`;

const PlanButton = styled.button`
  background-color: ${(props) => props.theme.colors.primary};
  color: #fff;
  border: none;
  padding: 15px;
  width: 100%;
  cursor: pointer;
  margin-top: 20px;
  border-radius: 8px;
  font-size: 18px;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: ${(props) => props.theme.colors.primaryDark};
    transform: translateY(-3px);
  }
`;

const PlanFeatures = styled.ul`
  list-style-type: none;
  padding: 0;
  text-align: left;
  margin-top: 20px;
  color: ${(props) => props.theme.colors.text};
`;

const PlanFeature = styled.li`
  margin: 12px 0;
  font-size: 17px;
`;

const BestValueTag = styled.div`
  position: absolute;
  top: -20px;
  right: -20px;
  background-color: #ff9800;
  color: #fff;
  padding: 7px 12px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: ${bounce} 1s infinite alternate;
`;

// Pricing Component
const Pricing = () => {
  const [billingType, setBillingType] = useState('monthly');

  const handleBillingToggle = (type) => {
    setBillingType(type);
  };

  // Dynamic prices based on billing type
  const getPrice = (monthlyPrice, yearlyPrice) => {
    return billingType === 'monthly' ? monthlyPrice : yearlyPrice;
  };

  return (
    <PricingPage>
      <Title>{pricingPageContent.title}</Title>
      <Subtitle>{pricingPageContent.subtitle}</Subtitle>
      <BillingToggle>
        <BillingButton $isActive={billingType === 'monthly'} onClick={() => handleBillingToggle('monthly')}>
          {pricingPageContent.billingOptions.monthly}
        </BillingButton>
        <BillingButton $isActive={billingType === 'yearly'} onClick={() => handleBillingToggle('yearly')}>
          {pricingPageContent.billingOptions.yearly}
        </BillingButton>
      </BillingToggle>
      <PricingPlans>
        {pricingPageContent.plans.map((plan) => (
          <Plan key={plan.id} className={plan.isHighlighted ? 'highlight' : ''}>
            <PlanTitle>{plan.title}</PlanTitle>
            <p>{plan.description}</p>
            <PlanPrice>€{getPrice(plan.monthlyPrice, plan.yearlyPrice)} /mo</PlanPrice>
            <PlanButton>Choose {plan.title}</PlanButton>
            <PlanFeatures>
              {plan.features.map((feature, index) => (
                <PlanFeature key={index}>{feature}</PlanFeature>
              ))}
            </PlanFeatures>
            {plan.bestValueTag && <BestValueTag>{plan.bestValueTag}</BestValueTag>}
          </Plan>
        ))}
      </PricingPlans>
    </PricingPage>
  );
};

export default Pricing;
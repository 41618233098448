import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

// Styled Components
const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.text};
  text-align: center;
  padding: 20px;
`;

const Heading = styled.h1`
  font-size: 48px;
  margin-bottom: 20px;
  color: ${(props) => props.theme.colors.primary};
`;

const Message = styled.p`
  font-size: 18px;
  margin-bottom: 30px;
`;

const HomeButton = styled(Link)`
  background-color: ${(props) => props.theme.colors.primary};
  color: #fff;
  padding: 12px 24px;
  border-radius: 8px;
  text-decoration: none;
  font-size: 16px;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${(props) => props.theme.colors.primaryDark};
  }
`;

// NotFoundPage Component
const NotFoundPage = () => {
  return (
    <NotFoundContainer>
      <Heading>404 - Page Not Found</Heading>
      <Message>Oops! The page you're looking for doesn't exist.</Message>
      <HomeButton to="/">Go Back to Home</HomeButton>
    </NotFoundContainer>
  );
};

export default NotFoundPage;
import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Paper } from '@mui/material';
import axios from '../../../api/axios'; // Use the custom axios instance
import { toast, ToastContainer } from 'react-toastify'; // Import ToastContainer for notifications
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection

const StyledContainer = styled(Container)`
  margin-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
`;

const FormBox = styled(Paper)`
  padding: 40px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
  height: 48px;
  font-weight: bold;
  border-radius: 8px;
  color: #fff;
  background-color: #1976d2;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    background-color: #1565c0;
  }
`;

const CreateTenantPage = () => {
  const [tenantName, setTenantName] = useState('');
  const [subdomain, setSubdomain] = useState(''); // New state for subdomain
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // Initialize navigate for redirection

  const handleCreateTenant = async () => {
    if (!tenantName || !subdomain) {
      toast.error('Both Tenant name and Subdomain are required.');
      return;
    }

    setLoading(true);

    const token = localStorage.getItem('access'); // Get the access token from localStorage

    try {
      const response = await axios.post(
        '/api/tenants/tenants/', // Updated to use the custom axios instance and correct endpoint
        { name: tenantName, subdomain: subdomain },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response);

      toast.success('Tenant created successfully!');
      setTenantName('');
      setSubdomain(''); // Clear both input fields after creation

      // Redirect to dashboard after a brief delay
      setTimeout(() => {
        navigate('/dashboard'); // Redirect back to the dashboard
      }, 2000); // Delay of 2 seconds to show the toast message

    } catch (error) {
      console.error('Failed to create tenant:', error);
      toast.error('Failed to create tenant. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledContainer maxWidth="md">
      <FormBox>
        <Typography variant="h4" gutterBottom align="center">
          Create a New Tenant
        </Typography>
        <TextField
          label="Tenant Name"
          variant="outlined"
          fullWidth
          margin="normal"
          value={tenantName}
          onChange={(e) => setTenantName(e.target.value)}
        />
        <TextField
          label="Subdomain"
          variant="outlined"
          fullWidth
          margin="normal"
          value={subdomain}
          onChange={(e) => setSubdomain(e.target.value)}
        />
        <StyledButton
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleCreateTenant}
          disabled={loading}
        >
          {loading ? 'Creating...' : 'Create Tenant'}
        </StyledButton>
      </FormBox>

      {/* Toast Container for Notifications */}
      <ToastContainer position="top-right" autoClose={2000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </StyledContainer>
  );
};

export default CreateTenantPage;
import React from 'react';
import styled from 'styled-components';
import { landingPageContent } from '../../content/landingPageContent'; // Import content

// Styled Components
const LandingPageContainer = styled.div`
  width: 100%;
  color: ${(props) => props.theme.colors.text};
  overflow-x: hidden;
  padding-top: 60px;
  font-family: ${(props) => props.theme.typography.fontFamily};
`;

const HeroSection = styled.section`
  background: linear-gradient(
    135deg,
    ${(props) => props.theme.colors.gradientStart},
    ${(props) => props.theme.colors.gradientEnd}
  );
  color: #fff;
  padding: 10vh 5vw;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    padding: 70px 20px;
  }

  @media (max-width: 480px) {
    padding: 50px 15px;
  }

  @media (forced-colors: active) {
    background: ButtonFace;
    color: ButtonText;
  }
`;

const HeroContent = styled.div`
  max-width: 800px;
  margin: 0 auto;

  h1 {
    font-family: 'Montserrat', sans-serif;
    font-size: clamp(32px, 6vw, 54px); /* Fluid typography */
    margin-bottom: 20px;
    animation: fadeInUp 0.5s ease-out;
  }

  p {
    font-family: ${(props) => props.theme.typography.fontFamily};
    font-size: clamp(16px, 4vw, 22px); /* Fluid typography */
    margin-bottom: 40px;
    animation: fadeInUp 0.6s ease-out;
  }
`;

const CTAButtons = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
  justify-content: center;
  flex-wrap: wrap;
  animation: fadeInUp 0.7s ease-out;

  @media (max-width: 480px) {
    flex-direction: column; /* Stack buttons vertically on mobile */
    gap: 10px;
  }
`;

const Button = styled.button`
  padding: 1rem 2rem;
  font-size: 1.125rem;
  border-radius: ${(props) => props.theme.borderRadius};
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: ${(props) => props.theme.boxShadow};

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 480px) {
    width: 100%; /* Make button full width on mobile */
  }

  @media (forced-colors: active) {
    background-color: ButtonFace;
    color: ButtonText;
  }
`;

const PrimaryButton = styled(Button)`
  background: linear-gradient(
    135deg,
    ${(props) => props.theme.colors.gradientStart},
    ${(props) => props.theme.colors.gradientEnd}
  );
  color: ${(props) => props.theme.colors.buttonText};

  &:hover {
    background: ${(props) => props.theme.colors.buttonHoverBackground};
    color: #fff;
  }
`;

const SecondaryButton = styled(Button)`
  background-color: transparent;
  color: ${(props) => props.theme.colors.buttonText};
  border: 2px solid ${(props) => props.theme.colors.primaryLight};

  &:hover {
    background-color: ${(props) => props.theme.colors.primaryDark};
    color: #fff;
    border-color: ${(props) => props.theme.colors.primaryDark};
  }

  @media (forced-colors: active) {
    background-color: ButtonFace;
    color: ButtonText;
    border-color: ButtonText;
  }
`;

const FeaturesSection = styled.section`
  padding: 10vh 5vw;
  text-align: center;
  background-color: ${(props) => props.theme.colors.backgroundLight};
  font-family: ${(props) => props.theme.typography.fontFamily};

  @media (max-width: 768px) {
    padding: 60px 15px;
  }

  @media (max-width: 480px) {
    padding: 40px 10px;
  }
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 30px;
`;

const FeatureCard = styled.div`
  background-color: #fff;
  border-radius: ${(props) => props.theme.borderRadius};
  box-shadow: ${(props) => props.theme.boxShadow};
  padding: 2rem;
  text-align: left;
  transition: transform 0.3s, box-shadow 0.3s;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  }

  h3 {
    font-size: 1.375rem;
    margin-bottom: 15px;
    color: ${(props) => props.theme.colors.primary};
  }

  p {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
  }
`;

const TestimonialsSection = styled.section`
  padding: 10vh 5vw;
  text-align: center;
  background-color: ${(props) => props.theme.colors.background};
  font-family: 'Montserrat', sans-serif;

  @media (max-width: 768px) {
    padding: 60px 15px;
  }

  @media (max-width: 480px) {
    padding: 40px 10px;
  }
`;

const CTASection = styled.section`
  padding: 10vh 5vw;
  text-align: center;
  background: linear-gradient(
    135deg,
    ${(props) => props.theme.colors.primaryDark},
    ${(props) => props.theme.colors.primaryLight}
  );
  color: #fff;

  @media (max-width: 768px) {
    padding: 60px 15px;
  }

  @media (max-width: 480px) {
    padding: 40px 10px;
  }
`;

// Landing Page Component
const LandingPage = () => {
  return (
    <LandingPageContainer>
      {/* Header, Hero, and other sections */}
      <HeroSection>
        <HeroContent>
          <h1>{landingPageContent.heroSection.title}</h1>
          <p>{landingPageContent.heroSection.subtitle}</p>
          <CTAButtons>
            <PrimaryButton>{landingPageContent.heroSection.primaryButtonText}</PrimaryButton>
            <SecondaryButton>{landingPageContent.heroSection.secondaryButtonText}</SecondaryButton>
          </CTAButtons>
        </HeroContent>
      </HeroSection>

      {/* Features Section */}
      <FeaturesSection>
        <h2>{landingPageContent.featuresSection.title}</h2>
        <FeaturesGrid>
          {landingPageContent.featuresSection.features.map((feature, index) => (
            <FeatureCard key={index}>
              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
            </FeatureCard>
          ))}
        </FeaturesGrid>
      </FeaturesSection>

      {/* Testimonials Section */}
      <TestimonialsSection>
        <h2>{landingPageContent.testimonialsSection.title}</h2>
        <div className="testimonials">
          {landingPageContent.testimonialsSection.testimonials.map((testimonial, index) => (
            <div key={index}>
              <blockquote>"{testimonial.quote}"</blockquote>
              <p>- {testimonial.author}</p>
            </div>
          ))}
        </div>
      </TestimonialsSection>

      {/* CTA Section */}
      <CTASection>
        <h2>{landingPageContent.ctaSection.title}</h2>
        <PrimaryButton>{landingPageContent.ctaSection.buttonText}</PrimaryButton>
      </CTASection>
    </LandingPageContainer>
  );
};

export default LandingPage;
// src/context/AuthContext.js

import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../api/axios'; // Import axios instance

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  // Memoize logout function using useCallback
  const logout = useCallback(() => {
    localStorage.removeItem('access');
    setIsAuthenticated(false);
    setUser(null);
    navigate('/');
  }, [navigate]); // Include 'navigate' in the dependency array of useCallback

  useEffect(() => {
    const token = localStorage.getItem('access');
    if (token) {
      setIsAuthenticated(true);

      // Move fetchUserDetails inside useEffect
      const fetchUserDetails = async (token) => {
        try {
          const response = await axios.get('/api/accounts/user/', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setUser(response.data); // Set the user data from the response
        } catch (error) {
          console.error('Failed to fetch user details:', error);
          logout();  // Use the memoized logout function
        }
      };

      fetchUserDetails(token); // Fetch user details after retrieving the token
    }
  }, [logout]); // Include 'logout' in the dependency array

  const login = (token, userData) => {
    localStorage.setItem('access', token);
    setIsAuthenticated(true);
    setUser(userData);

    // Fetch user details upon login
    const fetchUserDetails = async (token) => {
      try {
        const response = await axios.get('/api/accounts/user/', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUser(response.data); // Set the user data from the response
      } catch (error) {
        console.error('Failed to fetch user details:', error);
        logout();
      }
    };
    fetchUserDetails(token);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
// src/pages/FeaturesPage.js

import React from 'react';
import styled from 'styled-components';
import { featuresPageContent } from '../../content/featuresPageContent'; // Import content

// Styled Components
const FeaturesPageContainer = styled.div`
  width: 100%;
  color: ${(props) => props.theme.colors.text};
  overflow-x: hidden;
  margin-top: 0;
  padding-top: 0;
  font-family: 'Open Sans', sans-serif; /* Use the same font family as LandingPage */
`;

const HeroSection = styled.section`
  background: linear-gradient(135deg, ${(props) => props.theme.colors.gradientStart}, ${(props) => props.theme.colors.gradientEnd});
  color: #fff;
  padding: 100px 20px 40px;
  text-align: center;
  font-family: 'Montserrat', sans-serif; /* Match the font family */
`;

const FeaturesSection = styled.section`
  padding: 80px 20px;
  text-align: center;
  background-color: #f0f4f8;
  font-family: 'Open Sans', sans-serif; /* Same as LandingPage */
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  margin-top: 30px;
`;

const FeatureCard = styled.div`
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  padding: 30px;
  text-align: left;
  transition: transform 0.3s, box-shadow 0.3s;
  font-family: 'Open Sans', sans-serif; /* Same as LandingPage */

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  }

  h3 {
    font-size: 22px;
    margin-bottom: 15px;
    color: ${(props) => props.theme.colors.primary};
  }

  p {
    font-size: 16px;
    color: #555;
    line-height: 1.6;
  }
`;

const FeaturesPage = () => {
  return (
    <FeaturesPageContainer>
      <HeroSection>
        <h1>{featuresPageContent.heroSection.title}</h1>
        <p>{featuresPageContent.heroSection.subtitle}</p>
      </HeroSection>

      <FeaturesSection>
        <h2>{featuresPageContent.featureHighlights.title}</h2>
        <FeaturesGrid>
          {featuresPageContent.featureHighlights.features.map((feature, index) => (
            <FeatureCard key={index}>
              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
            </FeatureCard>
          ))}
        </FeaturesGrid>
      </FeaturesSection>
    </FeaturesPageContainer>
  );
};

export default FeaturesPage;
// contents/featuresPageContent.js

export const featuresPageContent = {
    heroSection: {
      title: 'Powerful Features for Seamless School Management',
      subtitle: 'Our SaaS solution provides everything you need to manage your school efficiently, from student management to communication tools.',
    },
    featureHighlights: {
      title: 'Explore Our Features',
      features: [
        {
          title: 'Student Management',
          description: 'Easily manage student records, enrollment, attendance, and grades all in one place.',
        },
        {
          title: 'Teacher Management',
          description: 'Organize teacher schedules, assignments, and communications effortlessly.',
        },
        {
          title: 'Class Management',
          description: 'Simplify class scheduling, resources allocation, and class monitoring.',
        },
        {
          title: 'Payment Tracking',
          description: 'Track fees and payments securely with automated reminders and receipts.',
        },
        {
          title: 'Customizable Reporting',
          description: 'Generate and customize reports that provide insights into student performance and financials.',
        },
        {
          title: 'Communication Tools',
          description: 'Connect with students, teachers, and parents using built-in messaging and notifications.',
        },
      ],
    },
  };
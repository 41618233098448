// src/pages/ContactPage.js

import React, { useState } from 'react';
import styled from 'styled-components';
import { sendContactMessage } from '../../services/contactService'; // Import the service function

// Styled Components
const ContactPageContainer = styled.div`
  margin-top: ${(props) => props.theme.spacing.large}; /* Add top margin for spacing */
  padding: ${(props) => props.theme.spacing.large} ${(props) => props.theme.spacing.medium}; /* Use theme spacing */
  text-align: center;
  background-color: ${(props) => props.theme.colors.background}; /* Align with theme */
  font-family: ${(props) => props.theme.typography.fontFamily}; /* Align with theme */
`;

const Title = styled.h1`
  font-family: ${(props) => props.theme.typography.headingFont}; /* Use theme heading font */
  font-size: ${(props) => props.theme.typography.h1FontSize}; /* Use theme font size */
  color: ${(props) => props.theme.colors.primary}; /* Use theme primary color */
  margin-bottom: ${(props) => props.theme.spacing.small}; /* Use theme spacing */
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 80px;
    height: 4px;
    background: ${(props) => props.theme.colors.primary};
    margin: 10px auto;
    border-radius: 2px;
  }
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
  gap: ${(props) => props.theme.spacing.medium}; /* Use theme spacing */
`;

const Input = styled.input`
  padding: ${(props) => props.theme.spacing.small}; /* Use theme spacing */
  font-size: ${(props) => props.theme.typography.bodyFontSize}; /* Use theme font size */
  border: 1px solid ${(props) => props.theme.colors.primaryLight}; /* Theme color */
  border-radius: ${(props) => props.theme.borderRadius}; /* Theme border radius */
  outline: none;

  &:focus {
    border-color: ${(props) => props.theme.colors.primary}; /* Focus color */
  }
`;

const Textarea = styled.textarea`
  padding: ${(props) => props.theme.spacing.small}; /* Use theme spacing */
  font-size: ${(props) => props.theme.typography.bodyFontSize}; /* Use theme font size */
  border: 1px solid ${(props) => props.theme.colors.primaryLight}; /* Theme color */
  border-radius: ${(props) => props.theme.borderRadius}; /* Theme border radius */
  min-height: 150px;
  outline: none;

  &:focus {
    border-color: ${(props) => props.theme.colors.primary}; /* Focus color */
  }
`;

const SubmitButton = styled.button`
  background-color: ${(props) => props.theme.colors.primary}; /* Theme color */
  color: white;
  border: none;
  padding: ${(props) => props.theme.spacing.medium}; /* Use theme spacing */
  font-size: ${(props) => props.theme.typography.h3FontSize}; /* Theme font size */
  border-radius: ${(props) => props.theme.borderRadius}; /* Theme border radius */
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${(props) => props.theme.colors.primaryDark}; /* Hover color */
  }
`;

const ContactPage = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await sendContactMessage(formData);
      alert('Message sent successfully!');
      setFormData({ name: '', email: '', message: '' });
    } catch (error) {
      alert('An error occurred while sending your message. Please try again.');
    }
  };

  return (
    <ContactPageContainer>
      <Title>Contact Us</Title>
      <ContactForm onSubmit={handleSubmit}>
        <Input
          type="text"
          name="name"
          placeholder="Your Name"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <Input
          type="email"
          name="email"
          placeholder="Your Email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <Textarea
          name="message"
          placeholder="Your Message"
          value={formData.message}
          onChange={handleChange}
          required
        />
        <SubmitButton type="submit">Send Message</SubmitButton>
      </ContactForm>
    </ContactPageContainer>
  );
};

export default ContactPage;
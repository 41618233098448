// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import theme from './themes/theme';

// Components
import Navbar from './components/Navbar';
import TenantNavbar from './components/TenantNavbar';
import Footer from './components/Footer';

// Static
import PricingPage from './pages/static/PricingPage';
import LandingPage from './pages/static/LandingPage';
import FeaturesPage from './pages/static/FeaturesPage';
import ContactPage from './pages/static/ContactPage';

// Auth
import LoginPage from './pages/auth/LoginPage';
import RegisterPage from './pages/auth/RegisterPage';

// Dashboard
import DashboardPage from './pages/SaaS/dashboard/DashboardPage';
import CreateTenantPage from './pages/SaaS/tenants/CreateTenantPage';
import ViewTenantPage from './pages/SaaS/tenants/ViewTenantPage'; // Import ViewTenantPage
import EditTenantPage from './pages/SaaS/tenants/EditTenantPage'; // Import EditTenantPage

// Error
import NotFoundPage from './pages/error/NotFoundPage';
import TenantNotFoundPage from './pages/error/TenantNotFoundPage';

// Tenant
import TenantLandingPage from './pages/tenant/TenantLandingPage';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import { AuthProvider, AuthContext } from './context/AuthContext';
import { getSubdomain } from './utils/getSubdomain';

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Content = styled.div`
  flex: 1;
`;

const RequireAuth = ({ children }) => {
  const { isAuthenticated } = React.useContext(AuthContext);
  return isAuthenticated ? children : <Navigate to="/login" />;
};

const App = () => {
  const subdomain = getSubdomain();  // Get the subdomain from the URL

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <AuthProvider>
          <AppContainer>
            {/* Conditionally render the tenant or default navbar */}
            {subdomain ? <TenantNavbar /> : <Navbar />}
            <Content>
              <Routes>
                {subdomain ? (  // If there is a subdomain, use TenantLandingPage
                  <>
                    <Route path="/" element={<TenantLandingPage />} />
                    <Route path="/tenant-not-found" element={<TenantNotFoundPage />} /> {/* Tenant Not Found Route */}
                  </>
                ) : (  // Otherwise, use the standard LandingPage
                  <Route path="/" element={<LandingPage />} />
                )}
                <Route path="/login" element={<LoginPage />} />
                <Route path="/register" element={<RegisterPage />} />
                <Route path="/pricing" element={<PricingPage />} />
                <Route path="/features" element={<FeaturesPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route
                  path="/dashboard"
                  element={
                    <RequireAuth>
                      <DashboardPage />
                    </RequireAuth>
                  }
                />
                {/* Add the route for CreateTenantPage */}
                <Route
                  path="/create-tenant"
                  element={
                    <RequireAuth>
                      <CreateTenantPage />
                    </RequireAuth>
                  }
                />
                {/* Add routes for ViewTenantPage and EditTenantPage */}
                <Route
                  path="/tenants/:tenantId"
                  element={
                    <RequireAuth>
                      <ViewTenantPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/tenants/edit/:tenantId"
                  element={
                    <RequireAuth>
                      <EditTenantPage />
                    </RequireAuth>
                  }
                />
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </Content>
            <Footer />
            <ToastContainer
              position="top-right"
              autoClose={2000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </AppContainer>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
};

export default App;
// src/api/axios.js

import axios from 'axios';

// Create an axios instance with default settings
const api = axios.create({
  baseURL: process.env.REACT_APP_ORPHIMUSE_BE_API_URL,  // Make sure this environment variable is set in your .env file
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor to include the token in all requests
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access');  // Get the access token from local storage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;  // Set the Authorization header
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default api;
import React, { useState } from 'react';
import styled from 'styled-components';
import { TextField, Button, Typography, Box, Container, CircularProgress } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { registerUser } from '../../services/authService'; // Import the service
import { toast } from 'react-toastify'; // Import toast from react-toastify
import 'react-toastify/dist/ReactToastify.css'; // Import toast CSS

// Styled Components
const RegisterContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: ${(props) => props.theme.colors.background};
`;

const RegisterBox = styled(Box)`
  padding: 40px;
  border-radius: ${(props) => props.theme.borderRadius};
  background-color: ${(props) => props.theme.colors.backgroundLight};
  box-shadow: ${(props) => props.theme.boxShadow};
  max-width: 400px;
  width: 100%;
`;

const StyledButton = styled(Button)`
  margin: 15px 0;
  height: 48px;
  font-weight: bold;
  border-radius: ${(props) => props.theme.borderRadius};
  color: #fff;
  background-color: ${(props) => props.theme.colors.primary}; /* Consistent primary color */
  box-shadow: ${(props) => props.theme.boxShadow};

  &:hover {
    background-color: ${(props) => props.theme.colors.primaryDark}; /* Slightly darker shade for hover */
  }
`;

const Register = () => {
  const [email, setEmail] = useState('');
  const [password1, setPassword1] = useState(''); // Use password1
  const [password2, setPassword2] = useState(''); // Use password2
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleRegister = async () => {
    setLoading(true);
    const userData = {
      email,
      password1, // Correctly use password1 for the API
      password2, // Correctly use password2 for the API
      first_name: firstName,
      last_name: lastName,
    };

    try {
      await registerUser(userData);
      toast.success('Registration successful! Please login.', { position: "top-right", autoClose: 2000 });
      navigate('/login'); // Navigate immediately after registration
    } catch (error) {
      console.error('Registration error:', error);
      if (error.response && error.response.data) {
        // Specific errors from the backend
        const errors = error.response.data;
        Object.keys(errors).forEach((key) => {
          toast.error(`${key.charAt(0).toUpperCase() + key.slice(1)}: ${errors[key]}`, { position: "top-right", autoClose: 3000 });
        });
      } else {
        toast.error('Registration failed. Please try again.', { position: "top-right", autoClose: 3000 });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <RegisterContainer>
      <RegisterBox>
        <Typography
          variant="h4"
          gutterBottom
          style={{ textAlign: 'center', marginBottom: '30px', fontWeight: 'bold' }}
        >
          Register
        </Typography>
        <TextField
          label="First Name"
          variant="outlined"
          fullWidth
          margin="normal"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <TextField
          label="Last Name"
          variant="outlined"
          fullWidth
          margin="normal"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          value={password1} // Use password1
          onChange={(e) => setPassword1(e.target.value)}
        />
        <TextField
          label="Confirm Password"
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          value={password2} // Use password2
          onChange={(e) => setPassword2(e.target.value)}
        />
        <StyledButton variant="contained" fullWidth onClick={handleRegister} disabled={loading}>
          {loading ? <CircularProgress size={24} color="inherit" /> : 'Register'}
        </StyledButton>
        <Typography style={{ marginTop: '20px', textAlign: 'center' }}>
          Already have an account? <Link to="/login" style={{ color: '#1976d2', fontWeight: 'bold' }}>Login</Link>
        </Typography>
      </RegisterBox>
    </RegisterContainer>
  );
};

export default Register;
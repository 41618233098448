// src/pages/LoginPage.js

import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { TextField, Button, Typography, Box, Container } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { Facebook, Google } from '@mui/icons-material';
import axios from '../../api/axios';  // Updated axios import to use custom axios instance
import { toast, ToastContainer } from 'react-toastify';
import { AuthContext } from '../../context/AuthContext';
import 'react-toastify/dist/ReactToastify.css';

const LoginContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: ${(props) => props.theme.colors.background};
`;

const LoginBox = styled(Box)`
  padding: 40px;
  border-radius: 15px;
  background-color: ${(props) => props.theme.colors.backgroundLight};
  box-shadow: ${(props) => props.theme.boxShadow};
  max-width: 400px;
  width: 100%;
`;

const StyledButton = styled(Button)`
  margin: 15px 0;
  height: 48px;
  font-weight: bold;
  border-radius: 8px;
  color: #fff;
  background-color: #1976d2;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  }
`;

const SocialButton = styled(StyledButton)`
  background-color: ${(props) => props.color};
`;

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { isAuthenticated, login } = useContext(AuthContext);
  const navigate = useNavigate();

  // Redirect to dashboard if already logged in
  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard');
    }
  }, [isAuthenticated, navigate]);

  const handleLogin = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await axios.post('/api/auth/login/', { email, password });  // Ensure correct endpoint

      const { access, refresh } = response.data;  // Expect access and refresh tokens
      if (!access) {
        throw new Error('Token is undefined. Check API response format and path.');
      }

      // Save tokens to localStorage and set user context
      localStorage.setItem('access', access);
      localStorage.setItem('refresh', refresh);
      login(access, { username: email });  // Use the login function from context to set the user state

      toast.success('Logged in successfully!');
      navigate('/dashboard');
    } catch (error) {
      console.error('Login failed:', error);
      setError('Invalid email or password. Please try again.');
      toast.error('Login failed. Please check your credentials.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoginContainer>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <LoginBox>
        <Typography variant="h4" gutterBottom style={{ textAlign: 'center', marginBottom: '30px', fontWeight: 'bold' }}>
          User Login
        </Typography>
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            style: { WebkitBoxShadow: '0 0 0 1000px white inset' },
          }}
          style={{ marginBottom: '20px' }}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            style: { WebkitBoxShadow: '0 0 0 1000px white inset' },
          }}
          style={{ marginBottom: '30px' }}
        />
        <StyledButton
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleLogin}
          disabled={loading}
        >
          {loading ? 'Loading...' : 'Login'}
        </StyledButton>
        {error && <Typography color="error">{error}</Typography>}
        <SocialButton fullWidth startIcon={<Facebook />} color="#3b5998">
          Login with Facebook
        </SocialButton>
        <SocialButton fullWidth startIcon={<Google />} color="#db4437">
          Login with Google
        </SocialButton>
        <Typography style={{ marginTop: '20px', textAlign: 'center' }}>
          Don't have an account? <Link to="/register" style={{ color: '#1976d2', fontWeight: 'bold' }}>Register</Link>
        </Typography>
      </LoginBox>
    </LoginContainer>
  );
};

export default LoginPage;
import React, { useContext, useState } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AuthContext } from '../context/AuthContext';  // Correctly import AuthContext
import { toast } from 'react-toastify';

// Styled Components
const StyledAppBar = styled(AppBar)`
  background: ${(props) => props.theme.colors.primary}; /* Use primary color from theme */
  box-shadow: ${(props) => props.theme.boxShadow};
`;

const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: white;
  color: ${(props) => props.theme.colors.primary}; 
`;

const Logo = styled(Typography)`
  font-family: 'Montserrat', sans-serif;
  color: ${(props) => props.theme.colors.primary}; /* Updated color to theme primary */
  text-decoration: none;
  font-weight: bold;
  font-size: 26px;
  &:hover {
    color: ${(props) => props.theme.colors.linkHover}; /* Use hover color from theme */
  }
`;

const NavButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-left: auto; /* Pushes the buttons to the right end */

  @media (max-width: 768px) { /* Adjust for mobile */
    display: none;
  }
`;

const StyledButton = styled(Button)`
  color: ${(props) => props.theme.colors.primary}; /* Blue text color */
  background-color: #fff; /* Solid white background */
  padding: 8px 18px;
  border-radius: ${(props) => props.theme.borderRadius}; /* Use border-radius from theme */
  border: 2px solid ${(props) => props.theme.colors.primary}; /* Border with primary color */
  text-transform: none;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease, color 0.3s ease;

  &:hover {
    background-color: ${(props) => props.theme.colors.primary}; /* Hover background */
    color: #fff; /* Change text color on hover */
    transform: translateY(-2px);
  }
`;

const Navbar = ({ toggleTheme, isDarkTheme }) => {
  const { isAuthenticated, logout, user } = useContext(AuthContext);  // Use AuthContext
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false); // State for controlling the drawer

  const handleLogout = () => {
    logout();
    navigate('/');
    toast.success('Logged out successfully!', { position: 'top-right', autoClose: 2000 }); // Show logout toast
  };

  // Toggle Drawer Open/Close
  const toggleDrawer = (open) => (event) => {
    // Prevent toggling the drawer if the event is a 'Tab' or 'Shift' key press
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    
    // Set the drawer state to open or closed
    setDrawerOpen(open);
  };

  return (
    <StyledAppBar position="fixed">
      <StyledToolbar>
        <Logo variant="h6" component={Link} to="/" sx={{ color: 'inherit' }}>
          Orphimuse
        </Logo>
        {/* Navigation Buttons */}
        <NavButtons>
          <StyledButton component={Link} to="/">Home</StyledButton>
          <StyledButton component={Link} to="/features">Features</StyledButton>
          <StyledButton component={Link} to="/pricing">Pricing</StyledButton>
          <StyledButton component={Link} to="/contact">Contact Us</StyledButton>
          {isAuthenticated ? (
            <>
              <StyledButton component={Link} to="/dashboard">Dashboard</StyledButton> {/* Dashboard link for logged in users */}
              <Typography variant="body1" style={{ margin: '0 8px' }}>
                Welcome, {user?.first_name || 'User'} {/* Display first_name from user object */}
              </Typography>
              <StyledButton onClick={handleLogout}>Logout</StyledButton>
            </>
          ) : (
            <StyledButton component={Link} to="/login">Login</StyledButton>
          )}
        </NavButtons>
        {/* Hamburger Menu for Mobile */}
        <IconButton edge="start" color="inherit" aria-label="menu" sx={{ display: { sm: 'block', md: 'none' } }} onClick={toggleDrawer(true)}>
          <MenuIcon />
        </IconButton>
        <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
          <List>
            <ListItem button component={Link} to="/" onClick={toggleDrawer(false)}>
              <ListItemText primary="Home" />
            </ListItem>
            <ListItem button component={Link} to="/features" onClick={toggleDrawer(false)}>
              <ListItemText primary="Features" />
            </ListItem>
            <ListItem button component={Link} to="/pricing" onClick={toggleDrawer(false)}>
              <ListItemText primary="Pricing" />
            </ListItem>
            <ListItem button component={Link} to="/contact" onClick={toggleDrawer(false)}>
              <ListItemText primary="Contact Us" />
            </ListItem>
            {isAuthenticated ? (
              <>
                <ListItem button component={Link} to="/dashboard" onClick={toggleDrawer(false)}>
                  <ListItemText primary="Dashboard" />
                </ListItem>
                <ListItem button onClick={() => { handleLogout(); toggleDrawer(false); }}>
                  <ListItemText primary="Logout" />
                </ListItem>
              </>
            ) : (
              <ListItem button component={Link} to="/login" onClick={toggleDrawer(false)}>
                <ListItemText primary="Login" />
              </ListItem>
            )}
          </List>
        </Drawer>
      </StyledToolbar>
    </StyledAppBar>
  );
};

export default Navbar;
import React, { useState, useEffect } from 'react';
import { Container, Typography, Paper, Button, Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';  // Import icon for the back button
import EditIcon from '@mui/icons-material/Edit';  // Import icon for the edit button
import axios from '../../../api/axios'; // Use the custom axios instance
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { useParams, useNavigate } from 'react-router-dom'; // Import useParams and useNavigate

const StyledContainer = styled(Container)`
  margin-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
`;

const InfoBox = styled(Paper)`
  padding: 40px;
  width: 100%;
  max-width: 600px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  background-color: #ffffff;
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
  height: 48px;
  font-weight: bold;
  border-radius: 8px;
  color: #fff;
  background-color: #1976d2;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    background-color: #1565c0;
  }
`;

const BackButton = styled(Button)`
  margin-bottom: 20px;
  color: #1976d2;
  text-transform: none;
`;

const ViewTenantPage = () => {
  const [tenant, setTenant] = useState(null); // State to store tenant details
  const { tenantId } = useParams(); // Get tenant ID from URL
  const navigate = useNavigate();

  useEffect(() => {
    if (tenantId) { // Ensure tenantId is not undefined
      const fetchTenantDetails = async () => {
        try {
          const token = localStorage.getItem('access');
          const response = await axios.get(`/api/tenants/tenants/${tenantId}/`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setTenant(response.data); // Store tenant data in state
        } catch (error) {
          console.error('Failed to fetch tenant details:', error);
          toast.error('Failed to fetch tenant details. Please try again.');
        }
      };

      fetchTenantDetails();
    }
  }, [tenantId]);

  return (
    <StyledContainer maxWidth="md">
      {tenant ? (
        <InfoBox>
          <BackButton startIcon={<ArrowBackIcon />} onClick={() => navigate('/dashboard')}>
            Back to Dashboard
          </BackButton>
          <Typography variant="h4" gutterBottom align="center">
            Tenant Details
          </Typography>
          <Box display="flex" flexDirection="column" gap={2} mt={2}>
            <Typography variant="h6">
              <strong>Name:</strong> {tenant.name}
            </Typography>
            <Typography variant="h6">
              <strong>Subdomain:</strong> {tenant.subdomain}
            </Typography>
            <Typography variant="h6">
              <strong>Owner:</strong> {tenant.owner}
            </Typography>
            <Typography variant="h6">
              <strong>Created At:</strong> {new Date(tenant.created_at).toLocaleDateString()}
            </Typography>
            <Typography variant="h6">
              <strong>Updated At:</strong> {new Date(tenant.updated_at).toLocaleDateString()}
            </Typography>
          </Box>
          <StyledButton
            variant="contained"
            color="primary"
            startIcon={<EditIcon />}
            onClick={() => navigate(`/tenants/edit/${tenantId}`)}
            fullWidth
          >
            Edit Tenant
          </StyledButton>
        </InfoBox>
      ) : (
        <Typography variant="h6">Loading tenant details...</Typography>
      )}
    </StyledContainer>
  );
};

export default ViewTenantPage;
// components/Footer.js

import React from 'react';
import styled from 'styled-components';

// Styled Components
const FooterSection = styled.footer`
  background-color: #222; /* Darker background for footer */
  color: #ddd; /* Lighter text color for contrast */
  padding: 60px 20px;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  max-width: 800px;
  margin: 0 auto;
  font-size: 16px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 15px;
  }

  ul li a {
    color: #ddd;
    text-decoration: none;
    font-size: 16px;
    transition: color 0.3s;

    &:hover {
      color: #38ef7d; /* Accent color on hover */
    }
  }

  .social-media a {
    color: #ddd;
    margin: 0 10px;
    text-decoration: none;
    font-size: 20px;
    transition: color 0.3s;

    &:hover {
      color: #38ef7d;
    }
  }

  .powered-by {
    margin-top: 10px;
    font-size: 14px;
    color: #888; /* Subtle color for the powered by text */
  }
`;

const Footer = () => {
  return (
    <FooterSection>
      <FooterContent>
        <ul className="footer-links">
          <li><a href="/">Home</a></li>
          <li><a href="/features">Features</a></li>
          <li><a href="/pricing">Pricing</a></li>
          <li><a href="/contact">Contact</a></li>
          <li><a href="/blog">Blog</a></li>
          <li><a href="/login">Login</a></li>
        </ul>
        <div className="contact-info">
          <p>Email: support@orphimuse.com</p>
        </div>
        <div className="social-media">
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a>
          <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">LinkedIn</a>
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a>
        </div>
        {/* Powered by Section */}
        <div className="powered-by">
          <p>Powered by <strong>Orphimuse</strong></p>
        </div>
      </FooterContent>
    </FooterSection>
  );
};

export default Footer;
import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Button, CircularProgress, Card, CardContent, Typography, Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import axios from '../../../api/axios';  // Use custom axios instance
import { toast } from 'react-toastify';
import { AuthContext } from '../../../context/AuthContext'; // Import AuthContext

const DashboardContainer = styled.div`
  padding: 40px;
  background-color: ${(props) => props.theme.colors.background};
  min-height: 100vh;
  margin-top: 60px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`;

const Title = styled.h1`
  font-size: 32px;
  color: ${(props) => props.theme.colors.primary};
  font-weight: bold;
`;

const WelcomeMessage = styled.p`
  font-size: 18px;
  color: ${(props) => props.theme.colors.text};
`;

const TenantCard = styled(Card)`
  margin: 20px 0;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Dashboard = () => {
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [tenants, setTenants] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState(null); // State to manage selected tenant for deletion
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false); // State to manage confirmation dialog

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get('/api/accounts/user/', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access')}`,
          },
        });
        console.log('User details fetched successfully:', response.data);

        // Fetch tenants after user details are successfully fetched
        fetchTenants();
      } catch (error) {
        console.error('Failed to fetch user details:', error);
        if (error.response && error.response.status === 401) {
          toast.error('Session expired or unauthorized. Please login again.');
          logout();
        } else {
          toast.error('Failed to fetch user details. Please try again.');
        }
      }
    };

    const fetchTenants = async () => {
      try {
        const response = await axios.get('/api/tenants/tenants/', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access')}`,
          },
        });
        setTenants(response.data);
      } catch (error) {
        console.error('Failed to fetch tenants:', error);
        toast.error('Failed to fetch tenants. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [navigate, logout]);

  const handleCreateTenant = () => {
    navigate('/create-tenant');
  };

  const handleViewTenant = (tenantId) => {
    navigate(`/tenants/${tenantId}`);
  };

  const handleEditTenant = (tenantId) => {
    navigate(`/tenants/edit/${tenantId}`);
  };

  const confirmDeleteTenant = (tenant) => {
    setSelectedTenant(tenant); // Set the tenant to be deleted
    setOpenConfirmDialog(true); // Open confirmation dialog
  };

  const handleDeleteTenant = async () => {
    if (!selectedTenant) return; // Ensure a tenant is selected

    try {
      await axios.delete(`/api/tenants/tenants/${selectedTenant.id}/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access')}`,
        },
      });
      toast.success('Tenant deleted successfully!');
      setTenants(tenants.filter((tenant) => tenant.id !== selectedTenant.id)); // Remove tenant from state after deletion
    } catch (error) {
      console.error('Failed to delete tenant:', error);
      toast.error('Failed to delete tenant. Please try again.');
    } finally {
      setOpenConfirmDialog(false); // Close the dialog after deletion attempt
      setSelectedTenant(null); // Clear the selected tenant
    }
  };

  const handleCloseDialog = () => {
    setOpenConfirmDialog(false);
    setSelectedTenant(null);
  };

  if (loading) {
    return (
      <DashboardContainer>
        <CircularProgress />
      </DashboardContainer>
    );
  }

  return (
    <DashboardContainer>
      <Header>
        <Title>Dashboard</Title>
      </Header>
      <WelcomeMessage>
        Welcome back, {user ? `${user.first_name || 'Guest'} ${user.last_name || ''}` : 'Guest'}!
      </WelcomeMessage>

      <Button variant="contained" color="primary" onClick={handleCreateTenant} style={{ margin: '20px 0' }}>
        Create New Tenant
      </Button>

      {tenants.length > 0 ? (
        <Grid container spacing={3}>
          {tenants.map((tenant) => (
            <Grid item xs={12} md={6} lg={4} key={tenant.id}>
              <TenantCard>
                <CardContent>
                  <Typography variant="h5" component="div">
                    {tenant.name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" style={{ marginBottom: '10px' }}>
                    Role: {tenant.user_role || 'N/A'}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" style={{ marginBottom: '10px' }}>
                    Subdomain: {tenant.subdomain}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    style={{ marginBottom: '10px' }}
                    onClick={() => window.open(`https://${tenant.subdomain}.tenant.turboloom.com`, '_blank')}
                  >
                    Go to {tenant.name} Portal
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    style={{ marginBottom: '10px' }}
                    onClick={() => handleViewTenant(tenant.id)}
                  >
                    View Details
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    onClick={() => handleEditTenant(tenant.id)}
                  >
                    Edit Tenant
                  </Button>
                  {tenant.user_role === 'owner' && ( // Only show delete button for the owner
                    <Button
                      variant="outlined"
                      color="error"
                      fullWidth
                      onClick={() => confirmDeleteTenant(tenant)}
                    >
                      Delete Tenant
                    </Button>
                  )}
                </CardContent>
              </TenantCard>
            </Grid>
          ))}
        </Grid>
      ) : (
        <p>No tenants found. You can create a new tenant using the button above.</p>
      )}

      {/* Confirmation dialog for deleting tenant */}
      <Dialog
        open={openConfirmDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete Tenant"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the tenant "{selectedTenant?.name}"? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteTenant} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </DashboardContainer>
  );
};

export default Dashboard;
import React, { useState, useContext, useEffect } from 'react';
import { TextField, Button, Typography, Box } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import axios from '../../api/axios';  // Use custom axios instance for consistency
import { toast, ToastContainer } from 'react-toastify';
import { AuthContext } from '../../context/AuthContext';
import 'react-toastify/dist/ReactToastify.css';
import { getSubdomain } from '../../utils/getSubdomain';  // Import utility function to get subdomain

// Styles using Material-UI components
const styles = {
  container: {
    display: 'flex',
    height: '100vh',
  },
  imageContainer: {
    flex: 1,
    backgroundImage: 'url(https://via.placeholder.com/800x1000)', // Dummy cover image
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  loginContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f7f7f7', // Light background color for login section
    padding: '20px',
  },
  loginBox: {
    backgroundColor: '#fff',
    padding: '40px 30px',
    borderRadius: '10px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    width: '100%',
    maxWidth: '400px',
  },
  button: {
    marginTop: '15px',
    height: '48px',
    fontWeight: 'bold',
    borderRadius: '8px',
    backgroundColor: '#1976d2',
    color: '#fff',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease',
  },
};

const TenantLandingPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [tenantName, setTenantName] = useState(null);  // State to hold tenant name if subdomain exists
  const { isAuthenticated, login } = useContext(AuthContext);
  const navigate = useNavigate();
  const subdomain = getSubdomain();  // Use utility to get subdomain

  // Redirect to dashboard if already logged in
  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard');
    }

    // Fetch tenant details based on the subdomain
    const fetchTenantDetails = async () => {
      if (subdomain) {
        try {
          const response = await axios.get(`/api/tenants/check-subdomain/${subdomain}/`);
          if (response.data.exists) {
            setTenantName(response.data.tenant_name);  // Set tenant name if subdomain exists
          } else {
            setTenantName(null);  // Clear tenant name if subdomain does not exist
          }
        } catch (error) {
          console.error('Error fetching tenant details:', error);
          setTenantName(null);  // Clear tenant name in case of an error
          navigate('/tenant-not-found');
        }
      }
    };

    fetchTenantDetails();
  }, [isAuthenticated, navigate, subdomain]);

  const handleLogin = async () => {
    setLoading(true);
    setError('');
    try {
      // Call the tenant-login endpoint that authenticates the user for the specific tenant
      const response = await axios.post('/api/auth/tenant-login/', {  // Updated endpoint for tenant login
        email,
        password,
        subdomain,  // Include the subdomain to check the association
      });
  
      const { access, refresh } = response.data;  // Expect access and refresh tokens
      if (!access) {
        throw new Error('Token is undefined. Check API response format and path.');
      }
  
      // Save tokens to localStorage and set user context
      localStorage.setItem('access', access);
      localStorage.setItem('refresh', refresh);
      login(access, { email });  // Use the login function from context to set the user state
  
      toast.success('Logged in successfully!');
      navigate('/dashboard');
    } catch (error) {
      console.error('Login failed:', error);
      setError('Invalid email or password, or you are not associated with this tenant. Please try again.');
      toast.error('Login failed. Please check your credentials or association with this tenant.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={styles.container}>
      {/* Left Side - Cover Image */}
      <div style={styles.imageContainer} />

      {/* Right Side - Login Box */}
      <div style={styles.loginContainer}>
        <Box style={styles.loginBox}>
          <Typography variant="h4" gutterBottom style={{ textAlign: 'center', marginBottom: '30px', fontWeight: 'bold' }}>
            {tenantName ? `${tenantName} Login` : 'Tenant Login'}  {/* Dynamically display tenant name if exists */}
          </Typography>
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            InputLabelProps={{ shrink: true }}
            style={{ marginBottom: '20px' }}
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputLabelProps={{ shrink: true }}
            style={{ marginBottom: '30px' }}
          />
          <Button
            variant="contained"
            fullWidth
            onClick={handleLogin}
            disabled={loading}
            style={styles.button}
          >
            {loading ? 'Loading...' : 'Login'}
          </Button>
          {error && <Typography color="error">{error}</Typography>}
          <Typography style={{ marginTop: '20px', textAlign: 'center' }}>
            Don't have an account? <Link to="/register" style={{ color: '#1976d2', fontWeight: 'bold' }}>Register</Link>
          </Typography>
        </Box>
      </div>

      {/* Toast Container for Notifications */}
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </div>
  );
};

export default TenantLandingPage;
// src/content/pricingPageContent.js

export const pricingPageContent = {
  title: 'Pricing Plans',
  subtitle: "Choose a plan that's right for you and your community.",
  billingOptions: {
    monthly: 'Monthly billing',
    yearly: 'Yearly billing',
  },
  plans: [
    {
      id: 1,
      title: 'Starter Pack',
      description: 'Perfect for individuals or small communities just getting started.',
      monthlyPrice: 7,
      yearlyPrice: 70,
      features: ['3 user accounts', 'Unlimited Slack users', '5,000 messages'],
    },
    {
      id: 2,
      title: 'Growth Pack',
      description: 'Ideal for growing communities looking for more flexibility.',
      monthlyPrice: 17,
      yearlyPrice: 170,
      features: ['15 user accounts', 'Unlimited Slack users', '25,000 messages'],
      isHighlighted: true,
      bestValueTag: 'Best Value',
    },
    {
      id: 3,
      title: 'Ultimate Pack',
      description: 'All-inclusive plan for large and dynamic communities.',
      monthlyPrice: 49,
      yearlyPrice: 490,
      features: [
        'Unlimited user accounts',
        'Unlimited Slack users',
        '200,000 messages',
        'Priority support',
        'Custom integrations',
      ],
    },
  ],
};
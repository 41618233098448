// src/utils/getSubdomain.js
export const getSubdomain = () => {
    const host = window.location.hostname; // Get the hostname (e.g., "swaralaya.tenant.turboloom.com")
    const parts = host.split('.'); // Split by '.'
  
    // Check if it's a subdomain (e.g., "swaralaya.tenant.turboloom.com")
    if (parts.length > 2) {
      return parts[0]; // "swaralaya"
    }
    return null; // No subdomain
  };
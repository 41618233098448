// src/pages/TenantNotFoundPage.js

import React from 'react';
import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Styles using Material-UI components
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#f0f0f5', // Light background color
    textAlign: 'center',
    padding: '20px',
  },
  heading: {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    marginBottom: '20px',
    color: '#333',
  },
  subheading: {
    fontSize: '1.2rem',
    color: '#666',
    marginBottom: '30px',
  },
  button: {
    marginTop: '20px',
    height: '48px',
    fontWeight: 'bold',
    borderRadius: '8px',
    backgroundColor: '#1976d2',
    color: '#fff',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease',
  },
};

const TenantNotFoundPage = () => {
  const handleExploreClick = () => {
    toast.success('Redirecting to our main page!');
window.location.href = 'https://turboloom.com'; // Redirect to the main landing page of your SaaS product
  };

  return (
    <div style={styles.container}>
      <Typography variant="h2" style={styles.heading}>
        Tenant Not Found
      </Typography>
      <Typography variant="body1" style={styles.subheading}>
        It seems like the tenant you're looking for doesn't exist. But don't worry! Check out our amazing SaaS platform and see how we can help your business.
      </Typography>
      <Button
        variant="contained"
        style={styles.button}
        onClick={handleExploreClick}
      >
        Explore Our SaaS Product
      </Button>
      <Typography style={{ marginTop: '20px', color: '#888' }}>
        Or go back to the <Link to="/" style={{ color: '#1976d2', fontWeight: 'bold' }}>Home Page</Link>.
      </Typography>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </div>
  );
};

export default TenantNotFoundPage;
import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Typography, Paper } from '@mui/material';
import axios from '../../../api/axios'; // Use the custom axios instance
import { toast, ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import { useParams, useNavigate } from 'react-router-dom'; // Import useParams and useNavigate

const StyledContainer = styled(Container)`
  margin-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
`;

const FormBox = styled(Paper)`
  padding: 40px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

const EditTenantPage = () => {
  const { tenantId } = useParams(); // Get tenant ID from URL
  const [tenantName, setTenantName] = useState('');
  const [subdomain, setSubdomain] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // Initialize navigate for redirection

  useEffect(() => {
    if (tenantId) {
      const fetchTenantDetails = async () => {
        try {
          const token = localStorage.getItem('access');
          const response = await axios.get(`/api/tenants/tenants/${tenantId}/`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setTenantName(response.data.name);
          setSubdomain(response.data.subdomain);
        } catch (error) {
          console.error('Failed to fetch tenant details:', error);
          toast.error('Failed to fetch tenant details. Please try again.');
        }
      };

      fetchTenantDetails();
    }
  }, [tenantId]);

  const handleEditTenant = async () => {
    if (!tenantName || !subdomain) {
      toast.error('Both Tenant name and Subdomain are required.');
      return;
    }

    setLoading(true);

    const token = localStorage.getItem('access'); // Get the access token from localStorage

    try {
      const response = await axios.put(
        `/api/tenants/tenants/${tenantId}/`, // Correct endpoint for updating tenant
        { name: tenantName, subdomain: subdomain },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response);
      toast.success('Tenant updated successfully!');

      // Redirect to dashboard after a brief delay
      setTimeout(() => {
        navigate('/dashboard'); // Redirect back to the dashboard
      }, 2000); // Delay of 2 seconds to show the toast message

    } catch (error) {
      console.error('Failed to update tenant:', error);
      toast.error('Failed to update tenant. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledContainer maxWidth="md">
      <FormBox>
        <Typography variant="h4" gutterBottom align="center">
          Edit Tenant
        </Typography>
        <TextField
          label="Tenant Name"
          variant="outlined"
          fullWidth
          margin="normal"
          value={tenantName}
          onChange={(e) => setTenantName(e.target.value)}
        />
        <TextField
          label="Subdomain"
          variant="outlined"
          fullWidth
          margin="normal"
          value={subdomain}
          onChange={(e) => setSubdomain(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleEditTenant}
          disabled={loading}
        >
          {loading ? 'Updating...' : 'Update Tenant'}
        </Button>
      </FormBox>

      {/* Toast Container for Notifications */}
      <ToastContainer position="top-right" autoClose={2000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </StyledContainer>
  );
};

export default EditTenantPage;
export const landingPageContent = {
    heroSection: {
      title: 'Make School Management Effortless',
      subtitle: 'Orphimuse: The ultimate SaaS solution for small school owners to manage their operations smoothly and efficiently.',
      primaryButtonText: 'Start Free Trial',
      secondaryButtonText: 'Learn More'
    },
    featuresSection: {
      title: 'Why Orphimuse?',
      features: [
        {
          title: 'Simplified Administration',
          description: 'Easily manage student records, attendance, and scheduling from one central dashboard.'
        },
        {
          title: 'Seamless Communication',
          description: 'Engage with parents, students, and staff with integrated messaging tools.'
        },
        {
          title: 'Financial Management',
          description: 'Automate billing, track payments, and generate financial reports with ease.'
        },
        {
          title: 'Customizable Reporting',
          description: 'Create and share reports that matter most to your school.'
        }
      ]
    },
    testimonialsSection: {
      title: 'Trusted by Small Schools Everywhere',
      testimonials: [
        {
          quote: 'Orphimuse has transformed the way we manage our school. It’s so easy to use!',
          author: 'John Doe, Principal at ABC School'
        },
        {
          quote: 'Our school’s communication has never been more effective. Highly recommend Orphimuse!',
          author: 'Jane Smith, Director at XYZ Academy'
        }
      ]
    },
    ctaSection: {
      title: 'Join hundreds of schools already loving Orphimuse!',
      buttonText: 'Get Started for Free'
    }
  };
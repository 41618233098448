// src/services/authService.js

import axios from 'axios';

const apiUrl = process.env.REACT_APP_ORPHIMUSE_BE_API_URL; // Ensure this is set in your .env file
const registerEndpoint = `${apiUrl}/api/accounts/register/`;  // Corrected endpoint for registration
const loginEndpoint = `${apiUrl}/api/auth/login/`;  // Corrected endpoint for login

// Function to register a new user
export const registerUser = async (userData) => {
  try {
    const response = await axios.post(registerEndpoint, userData); // API call to register a user
    return response.data; // Return response data
  } catch (error) {
    throw error; // Throw error for handling in the calling function
  }
};

// Function to login a user
export const loginUser = async (userData) => {
  try {
    const response = await axios.post(loginEndpoint, userData); // API call to login a user
    if (response.data.access) { // Assuming 'access' is the JWT token key
      localStorage.setItem('token', response.data.access);  // Save token to local storage
    }
    return response.data;
  } catch (error) {
    throw error; // Throw error for handling in the calling function
  }
};

// Function to log out a user
export const logoutUser = () => {
  localStorage.removeItem('token'); // Remove token from local storage
};

// Function to get the current user token
export const getCurrentUser = () => {
  return localStorage.getItem('token'); // Get token from local storage
};
// src/themes/theme.js

export const theme = {
  colors: {
    primary: '#1976d2', // Main blue color for elements
    primaryDark: '#004ba0', // Darker shade for hover effects
    primaryLight: '#63a4ff', // Lighter shade for button hover effects
    accent: '#4285F4', // Google Button Color
    background: '#f0f8ff', // Background color for the app
    backgroundLight: '#ffffff', // Light background color for elements
    text: '#333', // Text color
    link: '#40E0D0', // Link color
    linkHover: '#2F9D98', // Hover color for links
    buttonText: '#fff', // White text color for better visibility
    buttonBackground: '#1976d2', // Blue button background color
    buttonBorder: '#004ba0', // Slightly darker blue for button border
    buttonHoverBackground: '#0059b2', // Even darker blue for button hover background
    gradientStart: '#5a95ff', // Gradient start color
    gradientEnd: '#1e60d9', // Gradient end color
    secondaryText: '#666', // Secondary text color for subtitles and less important text
  },
  spacing: {
    small: '10px', // Small spacing
    medium: '20px', // Medium spacing
    large: '40px', // Large spacing
    padding: '20px', // General padding
  },
  borderRadius: '30px', // Increase border radius for a more rounded button look
  boxShadow: '0 6px 15px rgba(0, 0, 0, 0.15)', // Enhance the box shadow for more depth
  typography: {
    fontFamily: 'Roboto, sans-serif',
    headingFont: 'Montserrat, sans-serif', // Specific font for headings
    h1FontSize: '48px', // Font size for h1 elements
    h2FontSize: '32px', // Font size for h2 elements
    h3FontSize: '24px', // Font size for h3 elements
    bodyFontSize: '16px', // Font size for body text
  },
};

// Ensure lightTheme and darkTheme are derived from theme
export const lightTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    primary: '#1976d2', // Use the main blue color
    background: '#f5f5f5', // Light background
    backgroundLight: '#fff', // Lighter background for elements
    text: '#333', // Text color
    primaryLight: '#63a4ff', // Lighter primary color for hover
    primaryDark: '#004ba0', // Darker primary color for hover
  },
};

export const darkTheme = {
  ...theme,
  colors: {
    primary: '#90caf9', // Lighter blue for dark mode
    background: '#121212', // Dark background
    backgroundLight: '#1e1e1e', // Slightly lighter for contrast
    text: '#e0e0e0', // Light text color
    buttonText: '#90caf9', // Light blue for buttons in dark mode
    buttonBackground: '#1e1e1e', // Dark background for buttons
    buttonHoverBackground: '#333', // Slightly lighter hover effect
    gradientStart: '#90caf9', // Lighter gradient start for dark mode
    gradientEnd: '#1e88e5', // Darker gradient end for dark mode
  },
};

export default theme; // Default export for theme